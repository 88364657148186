import { Button, Divider, Skeleton, Text } from '@chakra-ui/react';
import Chat, { FILE_CONTEXT_LOCAL_STORAGE_KEY } from './Chat.js';
import {
  HistoryItemCategory,
  determineHistoryItemCategory,
  getCategoryTranslation,
} from '../../utils/historyItemsCategories';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import DeleteModal from '../../components/DeleteModal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HistoryItem from '../../components/HistoryItem.js';
import LogoPure from '../../components/Logo/LogoPure.js';
import MaiaLayout from '../../components/MaiaLayout.js';
import MaiaMenuPanel from '../../components/menu/MaiaMenuPanel.js';
import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { fetchApi } from '../../utils/useApi.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useChatsItems } from './useChats.js';

export const CHAT_ROUTE_CHAT_ID = 'chatId';

function ChatPage() {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const userInfo = useBoundStore((state) => state.userInfo);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { showToast } = useToastManagerHook();
  const navigate = useNavigate();
  const { chatId } = useTypedParams(ROUTES.HOME.CHAT);

  const [deleteChatModal, setDeleteChatModal] = useState<{
    id: string;
    name: string | null;
  }>();
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [chats, setChats, chatInitialized] = useChatsItems();
  const [loading, setLoading] = useState(false);

  const ChatCategoriesToDisplay = (): HistoryItemCategory[] => {
    return [
      HistoryItemCategory.TODAY,
      HistoryItemCategory.YESTERDAY,
      HistoryItemCategory.LAST_7_DAYS,
      HistoryItemCategory.LAST_30_DAYS,
      HistoryItemCategory.OLDER_THAN_A_MONTH,
    ];
  };

  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    // There are four cases:
    // 1. no chat existing and not in the url which should result in new Chat page => DEFAULT
    // 2. chat existing and in the url which should result in the correct chat being displayed => DEFAULT
    // 3. false chat_id but chats existing which should result in the most recent chat
    if (chatInitialized && chatId && chats.size && !chats.get(chatId)) {
      navigate(
        ROUTES.HOME.CHAT.buildPath({
          workspaceId,
          chatId: Array.from(chats.keys())[0],
        })
      );
    }
    // 4. false chat_id and no chat existing which should result in new Chat page
    if (chatInitialized && chatId && !chats.size) {
      navigate(
        ROUTES.HOME.CHAT.buildPath({
          workspaceId,
          chatId: '',
        })
      );
    }
  }, [chatId, chats, workspaceId, chatInitialized]);

  /**
   * Loads chats from the database and selects the most recent chat to navigate to.
   * Fetches all chats associated with the current workspace,
   * categorizes them based on their creation date, updates the chat state, and
   * navigates to the most recent chat.
   */
  async function loadChatsSelectMostRecent() {
    setLoading(true);

    const { data, error } = await supabase
      .from('chat')
      .select()
      .eq('workspace_id', workspaceId)
      .order('created_at', { ascending: false });

    if (!error && data) {
      const categorizedChats = data.map((chat) => ({
        ...chat,
        category: determineHistoryItemCategory(new Date(chat.created_at)),
      }));

      setChats(new Map(categorizedChats.map((chat) => [chat.id, chat])));

      // Navigate to the most recent chat if it exists
      if (categorizedChats.length > 0) {
        navigate(
          ROUTES.HOME.CHAT.buildPath({
            workspaceId,
            chatId: categorizedChats[0]?.id,
          })
        );
      }
    } else {
      console.error('Error fetching chats:', error?.message);
    }

    setLoading(false);
  }

  /**
   * Handles the creation of a new chat.
   * Inserts a new chat record into the database with the current
   * user's ID and workspace ID, then reloads the chats and navigates to the most
   * recent chat.
   */
  async function handleCreateChat() {
    const { data, error } = await supabase
      .from('chat')
      .insert({
        user_id: userInfo!.userId,
        workspace_id: workspaceId,
      })
      .select();

    if (error) {
      console.error('Error creating chat:', error.message);
    }

    // Add the new chat to the state and navigate to it
    if (data) {
      if (data[0] !== undefined) {
        const newChat = data[0];
        const categorizedChat = {
          ...newChat,
          category: determineHistoryItemCategory(new Date(newChat.created_at)),
        };
        setChats(new Map(chats.set(newChat.id, categorizedChat)));
        navigate(
          ROUTES.HOME.CHAT.buildPath({ workspaceId, chatId: newChat.id })
        );
      }
    }
  }

  /**
   * Fetches all chats associated with the current workspace,
   * categorizes them based on their creation date, and updates the chat state.
   * @param silent - If true, does not set the loading state. Defaults to false.
   */
  const fetchChats = async (silent?: boolean) => {
    setLoading(!silent);
    const { data, error } = await supabase
      .from('chat')
      .select()
      .eq('workspace_id', workspaceId)
      .order('created_at', { ascending: false });

    if (!error && data) {
      const categorizedChats = data.map((chat) => ({
        ...chat,
        category: determineHistoryItemCategory(new Date(chat.created_at)),
      }));

      setChats(new Map(categorizedChats.map((chat) => [chat.id, chat])));
    }

    setLoading(false);
  };

  /**
   * Handles the renaming of a chat.
   * Updates the topic of a chat in the database and reloads the specific chat
   * in the UI to reflect the new name.
   * @param name - The new name (topic) of the chat.
   * @param id - The ID of the chat to be renamed.
   * @returns The response from the database update operation.
   */
  async function handleRename(name: string, id: string) {
    const res = await supabase
      .from('chat')
      .update({ topic: name })
      .eq('id', id);

    if (!res.error) {
      reloadChat(id, name);
    }
    return res;
  }

  /**
   * Reloads a chat in the state with updated information.
   * @param chatId - The ID of the chat to reload.
   * @param name - The new name (topic) of the chat, if it has been changed.
   */
  function reloadChat(chatId: string, name: string) {
    const newMap = new Map(chats);
    const prevItem = newMap.get(chatId);
    prevItem &&
      newMap.set(chatId, {
        ...prevItem,
        topic: name,
      });
    setChats(newMap);
  }

  /**
   * Initiates adding a topic to a chat if it doesn't already have one.
   * This function calls core API to fetch a topic for the chat and updates the chat
   * topic in the state.
   * @param chatId - The ID of the chat to add a topic to.
   */
  function handleAddTopic(chatId: string) {
    const chat = chats.get(chatId);
    if (chat && !chat.topic) {
      fetchApi(supabase, '/chat', '/get_topic', {
        method: 'POST',
        chatId: chat.id,
      }).then((result) => {
        if (result.data?.topic) {
          reloadChat(chat.id, result.data.topic);
        }
      });
    }
  }

  /**
   * Renders chats categorized under a specific category.
   * @param category - The category of chats to render.
   * @returns A JSX element containing the categorized chats.
   */
  const renderChatCategory = (category: HistoryItemCategory) => {
    const chatsArray = Array.from(chats.values());
    const filteredChats = chatsArray
      .filter((chat) => chat.category === category)
      .sort(
        (chatA, chatB) =>
          new Date(chatB.created_at).getTime() -
          new Date(chatA.created_at).getTime()
      );
    return filteredChats.length > 0 ? (
      <>
        <Text className="font-medium">
          {getCategoryTranslation(category, t)}
        </Text>
        {filteredChats.map((chat) => (
          <div key={chat.id}>
            <Link
              to={ROUTES.HOME.CHAT.buildPath({
                workspaceId,
                chatId: chat.id,
              })}
            >
              <HistoryItem
                chatId={chat.id}
                chatTopic={chat.topic ?? undefined}
                defaultTopic={t('chat.undefinedChatTopic')}
                selected={chatId === chat.id}
                onDeleteClick={() => {
                  setDeleteChatModal({ id: chat.id, name: chat.topic });
                }}
                onRename={(name) => handleRename(name, chat.id)}
                domElementProps={{ onClick: () => setSidePanelOpen(false) }}
              />
            </Link>
          </div>
        ))}
        <Divider className="pb-1 pt-1" />
      </>
    ) : null;
  };

  return (
    <MaiaLayout
      sidePanelContent={
        <MaiaMenuPanel selectedNavigation={'CHAT'}>
          <div className="text-maia-text-dark flex min-h-0 flex-col gap-3 px-4 pt-3 ">
            <Button
              size="md"
              className="flex-shrink-0 text-sm"
              leftIcon={
                <FontAwesomeIcon className="text-maia-accent" icon={faPlus} />
              }
              onClick={handleCreateChat}
            >
              {t('chat.newChat')}
            </Button>
            <Divider />

            <div className="-mr-4 flex min-h-0 flex-col gap-2 overflow-auto pr-4">
              {loading
                ? Array.from({ length: 4 }).map((_, index) => (
                    <Skeleton className="h-11" key={index} />
                  ))
                : ChatCategoriesToDisplay().map((category) => (
                    <div key={category}>{renderChatCategory(category)}</div>
                  ))}
            </div>
          </div>
          <DeleteModal
            title={t('chat.deleteModalTitle')}
            subtitle={t('chat.deleteModalSubtitle')}
            isOpen={!!deleteChatModal}
            onConfirm={async () => {
              if (deleteChatModal) {
                const res = await supabase
                  .from('chat')
                  .delete()
                  .eq('id', deleteChatModal.id);
                if (res.error) {
                  captureException(res.error);
                  showToast({
                    title: t('general.reloadError'),
                    status: 'error',
                  });
                }
                if (res.status === 204) {
                  const copy = deleteChatModal.name;
                  showToast({
                    title: t('general.successfullyDeleted', {
                      name: `- ${copy || t('chat.undefinedChatTopic')} -`,
                    }),
                    status: 'success',
                  });
                  localStorage.removeItem(
                    `${FILE_CONTEXT_LOCAL_STORAGE_KEY}_${chatId}`
                  );
                }
                setDeleteChatModal(undefined);
                loadChatsSelectMostRecent();
              }
            }}
            onClose={() => setDeleteChatModal(undefined)}
          />
        </MaiaMenuPanel>
      }
      mainPanelContent={
        loading ? (
          <div className="bg-maia-neutral-background flex h-full w-full flex-col gap-1 px-10 pb-8">
            <div className="-mr-8 flex-grow overflow-auto pr-8">
              <div className="flex min-h-full flex-col justify-end gap-5 py-8">
                <Skeleton className="h-20 w-2/3 self-end" />
                <Skeleton className="h-20 w-2/3 self-start" />
                <Skeleton className="h-20 w-2/3 self-end" />
                <Skeleton className="h-20 w-2/3 self-start" />
                <Skeleton className="h-20 w-2/3 self-end" />
              </div>
            </div>
            <Skeleton className="h-10 flex-shrink-0 overflow-hidden rounded-md" />
          </div>
        ) : chatId ? (
          <Chat
            key={chatId}
            chatId={chatId}
            onFirstAnswerReceived={() => handleAddTopic(chatId)}
          />
        ) : (
          <div className="flex h-full flex-col items-center justify-center">
            <LogoPure className="fill-maia-gray-300" size={55} />
            <div className="text-maia-gray-300 mt-9 text-center text-3xl font-bold">
              {t('chat.emptyChatCatchphrase')}
            </div>
            <Button
              className="mt-8"
              colorScheme="maia-accent"
              variant="solid"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={handleCreateChat}
            >
              {t('chat.newChat')}
            </Button>
          </div>
        )
      }
      sidePanelOpenMobile={sidePanelOpen}
      onOpenSidePanelMobile={() => setSidePanelOpen(true)}
      onCloseSidePanelMobile={() => setSidePanelOpen(false)}
    />
  );
}

export default ChatPage;
