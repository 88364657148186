import { Button, IconButton, Tooltip } from '@chakra-ui/react';
import {
  HTMLAttributes,
  KeyboardEvent,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

import { ChatMessageType } from 'common-ts';
import CorrectAnswerModal from '../../components/CorrectAnswerModal.js';
import { ErrorBoundary } from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../components/Logo/Logo.js';
import QueryResults from '../../utils/QueryResults.js';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import RenderError from '../../components/RenderError.js';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi } from '../../utils/useApi.js';
import { preprocessAnswer } from '../../utils/preprocessAnswer.js';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export type SearchMessage = {
  type: 'SEARCH_REQUEST';
  msgId: string;
  msg: string;
  like?: boolean | null;
};

type ChatMessageProps = {
  props?: HTMLAttributes<HTMLDivElement>;
  msg: ChatMessageType | SearchMessage;
  likeInVisible?: boolean;
  onKeyUp?: (event: KeyboardEvent<HTMLElement>) => void;
  userIsMarker: boolean;
  handleInlineReferenceChange?: (index: number) => void;
  whiteLabel?: boolean;
};

function ChatMessage({
  props,
  msg,
  onKeyUp,
  likeInVisible,
  userIsMarker = false,
  handleInlineReferenceChange,
  whiteLabel = false,
}: ChatMessageProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const [isMarked, setIsMarked] = useState<boolean>(false);
  const { t } = useTranslation();
  const [like, setLike] = useState<boolean | undefined | null>(msg.like);
  const { className, ...rest } = props || {};
  const copyRef = useRef<HTMLDivElement>(null);
  const [correctAnswerModal, setCorrectAnswerModal] = useState<{
    id: string;
    answer: string;
  }>();
  const [focusInlineReference, setFocusInlineReference] = useState<number>(-1);

  const { showToast } = useToastManagerHook();

  const [copyTooltipString, setCopyTooltipString] = useState(t('general.copy'));

  const handleCopy = () => {
    if (!msg.msg) return;
    if (
      msg.type === 'MAIA' ||
      msg.type === 'MAIA_DOCUMENT' ||
      msg.type === 'SEARCH_REQUEST' ||
      msg.type === 'MAIA_DOCUMENT_POWER'
    ) {
      handleMetric('copyClick', msg.type);
    }
    // exclude inline references from copied text
    const text = msg.msg.replace(
      /<span className="inline-reference".*>.*<\/span>/gm,
      ''
    );

    navigator.clipboard.writeText(text);
    setCopyTooltipString(t('general.copied'));
    setTimeout(() => setCopyTooltipString(t('general.copy')), 1000);
  };

  useEffect(() => {
    if (msg.type === 'SEARCH_REQUEST' && userIsMarker && msg.msgId) {
      fetchApi(supabase, '/metric', '/marked', {
        method: 'POST',
        id: msg.msgId,
      }).then((res) => res.data && setIsMarked(res.data.marked));
    }
    // TODO also highlight that in chat (but its more effort)
    // TODO delete test set
  }, [userIsMarker, msg.type]);

  function handleMetric(
    what: 'copy' | 'like' | 'dislike' | 'copyClick' | 'canceled',
    type: 'MAIA' | 'MAIA_DOCUMENT' | 'SEARCH_REQUEST' | 'MAIA_DOCUMENT_POWER'
  ) {
    // message is still being generated and no Id is available
    // setting a queue for handling metric does not work because (different but same) component is remounted
    if (!msg.msgId) return;

    fetchApi(supabase, '/metric', '/various', {
      id: msg.msgId,
      method: 'POST',
      type:
        type === 'MAIA'
          ? 'cr'
          : type === 'MAIA_DOCUMENT' || type === 'MAIA_DOCUMENT_POWER'
            ? 'cdr'
            : 'sr',
      what,
      workspaceId,
    }).then((res) => {
      if (res.success === true && what === 'like') {
        setLike(true);
      }
      if (res.success === true && what === 'dislike') {
        setLike(false);
      }
    });
  }

  useEffect(() => {
    setLike(msg.like);
  }, [msg]);

  useEffect(() => {
    const copyListener = () => {
      if (
        msg.type === 'MAIA' ||
        msg.type === 'MAIA_DOCUMENT' ||
        msg.type === 'SEARCH_REQUEST' ||
        msg.type === 'MAIA_DOCUMENT_POWER'
      ) {
        handleMetric('copy', msg.type);
      }
    };
    if (
      msg.type === 'MAIA' ||
      msg.type === 'MAIA_DOCUMENT' ||
      msg.type === 'SEARCH_REQUEST' ||
      msg.type === 'MAIA_DOCUMENT_POWER'
    ) {
      if (copyRef.current) {
        copyRef.current.addEventListener('copy', copyListener);
      }
    }
    return () => {
      copyRef.current?.removeEventListener('copy', copyListener);
    };
  }, []);

  const correctAnswerModalComponent = useMemo(
    () => (
      <CorrectAnswerModal
        isOpen={!!correctAnswerModal}
        answer={correctAnswerModal?.answer || ''}
        onClose={() => setCorrectAnswerModal(undefined)}
        onConfirm={(answer) => {
          if (
            msg.type === 'MAIA_DOCUMENT' ||
            msg.type === 'MAIA_DOCUMENT_POWER'
          ) {
            fetchApi(supabase, '/metric', '/chat', {
              method: 'POST',
              answer_chat_message_id: msg.msgId,
              answer,
            }).then((resp) => {
              if (resp.data?.reason) {
                showToast({
                  title: resp.data.reason,
                  status: 'error',
                });
              }
            });
          }
          if (msg.type === 'SEARCH_REQUEST') {
            fetchApi(supabase, '/metric', '/search', {
              method: 'POST',
              search_answer_id: msg.msgId,
              answer,
            }).then((resp) => {
              if (resp.data?.reason) {
                showToast({
                  title: resp.data.reason,
                  status: 'error',
                });
              }
            });
          }
        }}
        subtitle={t('general.addTestSubtitle')}
        title={t('general.addTestTitle')}
      />
    ),
    []
  );

  return (
    <div
      {...rest}
      className={twMerge(
        `group flex flex-col items-start gap-2.5 rounded-xl border bg-white px-4 py-3 ${
          isMarked ? ' bg-green-300' : ''
        }`,
        className || ''
      )}
      onKeyUp={onKeyUp}
      tabIndex={0}
    >
      <div className="flex w-full items-center justify-between">
        {!(whiteLabel && !msg.type.includes('USER')) && (
          <div
            className={`text-maia-text-light flex h-5 items-center rounded-full px-2 text-xs font-semibold ${
              !msg.type.includes('USER')
                ? 'bg-maia-blue-400'
                : 'bg-maia-brand-blue'
            }`}
          >
            {!msg.type.includes('USER') ? <Logo size={10} /> : t('chat.you')}
          </div>
        )}
      </div>
      <div ref={copyRef} className="w-full min-w-0 overflow-x-auto font-medium">
        {msg.type === 'USER' ||
        msg.type === 'USER_DOCUMENT' ||
        msg.type === 'USER_DOCUMENT_POWER' ? (
          <div className="flex w-full items-center justify-between">
            <p>
              {msg.msg.split('\n').map((el, index, array) =>
                index === array.length - 1 ? (
                  <React.Fragment key={index}>{el}</React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    {el}
                    <br />
                  </React.Fragment>
                )
              )}
            </p>
            <Tooltip label={copyTooltipString} closeDelay={500}>
              <IconButton
                className="text-chakra-gray-500 self-end opacity-0 transition-opacity group-hover:opacity-100"
                variant={'ghost'}
                aria-label={t('general.copy')}
                icon={<FontAwesomeIcon icon={faCopy} />}
                size={'xs'}
                onClick={handleCopy}
              />
            </Tooltip>
          </div>
        ) : (
          <>
            <ErrorBoundary fallback={<RenderError content={msg.msg} />}>
              <ReactMarkdown
                className="prose min-w-0 max-w-none text-sm font-medium"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw as any]}
                components={{
                  code(props) {
                    const { children, className, ref, ...rest } = props;
                    const match = /language-(\w+)/.exec(className || '');
                    return match ? (
                      <SyntaxHighlighter
                        ref={ref as Ref<SyntaxHighlighter>}
                        {...rest}
                        children={String(children).replace(/\n$/, '')}
                        style={vscDarkPlus}
                        language={match[1]}
                        PreTag="div"
                      />
                    ) : (
                      <code {...rest} className={className}>
                        {children}
                      </code>
                    );
                  },
                  span(props) {
                    const { className, ...rest } = props;

                    // add custom styling for inline references
                    if (className === 'inline-reference') {
                      return (
                        <span
                          {...rest}
                          className="text-maia-support-blue cursor-pointer hover:underline"
                          onMouseDown={() => {
                            if (
                              [
                                'MAIA_DOCUMENT',
                                'MAIA_DOCUMENT_POWER',
                                'SEARCH_REQUEST',
                              ].includes(msg.type)
                            ) {
                              const label = rest['aria-label'];
                              if (label) {
                                const index = parseInt(label, 10);
                                setFocusInlineReference(index - 1);
                                handleInlineReferenceChange?.(index - 1);
                              }
                            }
                          }}
                        />
                      );
                    }
                    // Return default span for other cases
                    return <span {...props} />;
                  },
                  a(props) {
                    // open links in new tab
                    return <a {...props} target="_blank" rel="noreferrer" />;
                  },
                }}
              >
                {/* Tested this out. Although, it will call preprocessAnswer multiple times, the impact on performance is not noticeable.
                On the plus side, user will not notice the change. */}
                {preprocessAnswer(msg.msg)}
              </ReactMarkdown>
            </ErrorBoundary>
            {'aborted' in msg && msg.aborted ? (
              <span className="text-maia-support-red">
                {t('chat.generationAborted')}
              </span>
            ) : null}
          </>
        )}
      </div>
      {msg.type === 'MAIA_DOCUMENT' || msg.type === 'MAIA_DOCUMENT_POWER' ? (
        <QueryResults
          snippets={msg.metadata}
          isPowerMode={msg.type === 'MAIA_DOCUMENT_POWER'}
          wholeFiles={msg.type === 'MAIA_DOCUMENT_POWER' ? msg.wholeFiles : []}
          fileSummaries={
            msg.type === 'MAIA_DOCUMENT_POWER' ? msg.fileSummaries : []
          }
          focusInlineReference={focusInlineReference}
          clearInlineReference={() => setFocusInlineReference(-1)}
          glossaryUsed={msg.glossaryUsed}
        />
      ) : null}
      {!likeInVisible &&
        (msg.type === 'MAIA' ||
          msg.type === 'MAIA_DOCUMENT' ||
          msg.type === 'MAIA_DOCUMENT_POWER' ||
          msg.type === 'SEARCH_REQUEST') && (
          <div className="flex w-full justify-between">
            {userIsMarker && msg.type !== 'MAIA' ? (
              <Button
                size="sm"
                variant="outline"
                colorScheme={`${isMarked ? 'blue' : 'green'}`}
                onClick={() => {
                  setCorrectAnswerModal({
                    id: msg.msgId,
                    answer: msg.msg,
                  });
                }}
              >
                Add to test dataset
              </Button>
            ) : (
              <div />
            )}
            <div className="flex items-center justify-center space-x-1">
              <IconButton
                aria-label="like-button"
                size={'xs'}
                variant={'ghost'}
                className={`opacity-0 transition-opacity group-hover:opacity-100 ${like === true ? 'text-black' : 'text-chakra-gray-500'}`}
                onClick={() => {
                  if (
                    msg.type === 'MAIA' ||
                    msg.type === 'MAIA_DOCUMENT' ||
                    msg.type === 'MAIA_DOCUMENT_POWER' ||
                    msg.type === 'SEARCH_REQUEST'
                  ) {
                    handleMetric('like', msg.type);
                  }
                }}
                icon={<FontAwesomeIcon icon={faThumbsUp} />}
              />
              <IconButton
                aria-label="dislike-button"
                variant={'ghost'}
                size={'xs'}
                className={`opacity-0 transition-opacity group-hover:opacity-100 ${like === false ? 'text-black' : 'text-chakra-gray-500'}`}
                onClick={() => {
                  if (
                    msg.type === 'MAIA' ||
                    msg.type === 'MAIA_DOCUMENT' ||
                    msg.type === 'MAIA_DOCUMENT_POWER' ||
                    msg.type === 'SEARCH_REQUEST'
                  ) {
                    handleMetric('dislike', msg.type);
                  }
                }}
                icon={<FontAwesomeIcon icon={faThumbsDown} />}
              />
              <Tooltip label={copyTooltipString} closeDelay={500}>
                <IconButton
                  className="text-chakra-gray-500 opacity-0 transition-opacity group-hover:opacity-100"
                  variant={'ghost'}
                  aria-label={t('general.copy')}
                  icon={<FontAwesomeIcon icon={faCopy} />}
                  size={'xs'}
                  onClick={handleCopy}
                />
              </Tooltip>
            </div>
          </div>
        )}
      {correctAnswerModalComponent}
    </div>
  );
}

export default ChatMessage;
