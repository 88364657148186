import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
} from '@chakra-ui/react';
import {
  EnrichedBasicLayoutItem,
  SnippetResultsV1,
} from '../../../common-ts/src/types/chatEndpoint';
import { FileNameId, ROUTES } from 'common-ts';
import {
  faArrowDownLeft,
  faArrowUpRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';

import ChatSnippetResult from './SnippetResult.js';
import DocumentSummaryResult from '../pages/chat/DocumentSummaryResult.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { abstractV1V2 } from './resultsV1V2Conversion.js';
import { faBookOpen } from '@fortawesome/pro-regular-svg-icons';
import { useBoundStore } from '../store/useBoundStore';
import { useTranslation } from 'react-i18next';

type QueryResultsProps = {
  snippets: SnippetResultsV1 | EnrichedBasicLayoutItem;
  isPowerMode: boolean;
  focusInlineReference: number;
  clearInlineReference: () => void;
  fileSummaries?: FileNameId[];
  wholeFiles?: FileNameId[];
  isExpanded?: boolean;
  glossaryUsed?: boolean;
};
function QueryResults({
  snippets,
  isPowerMode,
  focusInlineReference,
  clearInlineReference,
  fileSummaries = [],
  wholeFiles = [],
  isExpanded = false,
  glossaryUsed = false,
}: QueryResultsProps) {
  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const [index, setIndex] = useState<number | number[]>(isExpanded ? [0] : []);

  const abstraction = useMemo(() => abstractV1V2(snippets), [snippets]);

  const handleAccordionChange = (expandedIndex: number | number[]) => {
    setIndex(expandedIndex);
  };
  const snippetsResultJSX = () => (
    <AccordionItem border={'none'}>
      {({ isExpanded }) => (
        <>
          {/* based on Chakra Docs, AccordionButton should be wrapped in
              heading role. */}
          <h2>
            <AccordionButton
              padding={0}
              className="flex w-full justify-between"
            >
              <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
                {t('general.foundXSnippets', {
                  amount: abstraction.length,
                })}
              </div>
              <Button
                size={'xs'}
                rightIcon={
                  <FontAwesomeIcon
                    className="text-xs"
                    icon={isExpanded ? faArrowUpRight : faArrowDownLeft}
                  />
                }
                className="flex-shrink-0 text-xs"
                colorScheme="maia-accent"
              >
                {isExpanded ? t('general.collapse') : t('general.expand')}
              </Button>
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0} className="flex flex-col gap-[10px]">
            <div className="bg-maia-border h-[1px] w-full" />
            <ChatSnippetResult
              result={abstraction}
              focusInlineReference={focusInlineReference}
              clearInlineReference={clearInlineReference}
            />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
  const fileSummariesJSX = () => (
    <AccordionItem border="none">
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton
              padding={0}
              className="flex w-full justify-between"
            >
              <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
                {t('general.usedSummary')}: {fileSummaries.length}
              </div>
              <Button
                size={'xs'}
                rightIcon={
                  <FontAwesomeIcon
                    className="text-xs"
                    icon={isExpanded ? faArrowUpRight : faArrowDownLeft}
                  />
                }
                className="flex-shrink-0 text-xs"
                colorScheme="maia-accent"
              >
                {isExpanded ? t('general.collapse') : t('general.expand')}
              </Button>
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0} className="flex flex-col gap-[10px]">
            <div className="bg-maia-gray-200 h-[1px] w-full" />
            <DocumentSummaryResult summariesOrWholeDocs={fileSummaries} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
  const wholeFilesJSX = () => (
    <AccordionItem border="none">
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton
              padding={0}
              className="flex w-full justify-between"
              onClick={() => {
                setIndex((prevIndex) => (prevIndex === -1 ? 0 : -1));
              }}
            >
              <div className="text-chakra-gray-500 py-[10px] text-xs font-medium">
                {t('general.usedWhole')}: {wholeFiles.length}
              </div>
              <Button
                size={'xs'}
                rightIcon={
                  <FontAwesomeIcon
                    className="text-xs"
                    icon={isExpanded ? faArrowUpRight : faArrowDownLeft}
                  />
                }
                className="flex-shrink-0 text-xs"
                colorScheme="maia-accent"
              >
                {isExpanded ? t('general.collapse') : t('general.expand')}
              </Button>
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0} className="flex flex-col gap-[10px]">
            <div className="bg-maia-gray-200 h-[1px] w-full" />
            <DocumentSummaryResult summariesOrWholeDocs={wholeFiles} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );

  useEffect(() => {
    if (focusInlineReference !== -1) setIndex([0]);
  }, [focusInlineReference]);
  return (
    <Accordion
      className="w-full"
      index={index}
      onChange={handleAccordionChange}
      allowMultiple
    >
      {/* TODO v2 might make sense displayed another way */}
      {abstraction.length ? snippetsResultJSX() : null}
      {isPowerMode && fileSummaries?.length ? fileSummariesJSX() : null}
      {isPowerMode && wholeFiles?.length ? wholeFilesJSX() : null}
      {glossaryUsed ? (
        <div
          className={
            'border-maia-border mt-2 flex w-full cursor-pointer items-center justify-between gap-[10px] rounded border px-0 py-0 font-bold md:px-4 md:py-2'
          }
        >
          <div className="text-maia-accent flex items-center gap-2">
            <FontAwesomeIcon icon={faBookOpen} />
            <Link
              to={ROUTES.HOME.SETTINGS.WORKSPACE.GLOSSARY.buildPath({
                workspaceId,
              })}
              target="_blank"
            >
              <span className="font-medium leading-[26px]">
                {t('general.viewGlossary')}
              </span>
            </Link>
          </div>
        </div>
      ) : null}
    </Accordion>
  );
}

export default QueryResults;
