import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';

import SearchBar from '../../components/SearchBar';
import { useApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';
import { zeros } from 'common-ts';

type SharepointSiteSelectionModalProps = {
  isOpen: boolean;
  initialSelectedSiteIds: string[];
  onNewSitesSelected: (sharepointSiteIds: string[]) => void;
  onClose: () => void;
};

function SharepointSiteSelectionModal({
  isOpen,
  initialSelectedSiteIds,
  onNewSitesSelected,
  onClose,
}: SharepointSiteSelectionModalProps) {
  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedSiteIds, setSelectedSiteIds] = useState(
    initialSelectedSiteIds
  );

  const { data: sharepointSites, error } = useApi(
    supabase,
    '/integrations',
    '/sharepoint/available_sites',
    { method: 'POST', workspaceId }
  );

  useEffect(() => {
    if (error) {
      showToast({
        title: t('general.tryAgainError'),
        status: 'error',
      });
    }
  }, [error]);

  function handleSiteSelectToggle({ siteId }: { siteId: string }) {
    if (selectedSiteIds.includes(siteId)) {
      setSelectedSiteIds(selectedSiteIds.filter((id) => id !== siteId));
    } else {
      setSelectedSiteIds([...selectedSiteIds, siteId]);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="max-h-[512px] self-stretch">
        <ModalHeader>
          {t('integrationSettings.sharepoint.siteSelectionModal.title')}
        </ModalHeader>
        <ModalBody className="flex min-h-0 flex-col gap-4">
          <div>
            {t(
              'integrationSettings.sharepoint.siteSelectionModal.availableSites'
            )}
          </div>
          <SearchBar
            onSearchTermChange={(newTerm) => {
              setSearchTerm(newTerm);
            }}
          />

          <div className="flex min-h-0 flex-col gap-2 overflow-y-auto">
            {sharepointSites
              ? sharepointSites?.sites.reduce((elements, site) => {
                  if (
                    initialSelectedSiteIds.includes(site.siteId) ||
                    !site.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return elements;
                  }

                  elements.push(
                    <Checkbox
                      className="flex-shrink-0 truncate"
                      key={site.siteId}
                      isChecked={selectedSiteIds.includes(site.siteId)}
                      onChange={() => {
                        handleSiteSelectToggle({ siteId: site.siteId });
                      }}
                    >
                      {site.name}
                    </Checkbox>
                  );
                  return elements;
                }, [] as ReactElement[])
              : zeros(5).map((_, index) => {
                  return (
                    <Skeleton key={index}>
                      {/* This is just here to get the right height for the skeleton */}
                      <Checkbox>Loading</Checkbox>
                    </Skeleton>
                  );
                })}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            variant={'outline'}
            mr={2}
            onClick={onClose}
          >
            {t('general.cancelButton')}
          </Button>
          <Button
            colorScheme="maia-accent"
            onClick={() => {
              onNewSitesSelected(
                selectedSiteIds.filter(
                  (id) => !initialSelectedSiteIds.includes(id)
                )
              );
              onClose();
            }}
          >
            {t(
              'integrationSettings.sharepoint.siteSelectionModal.confirmButton'
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SharepointSiteSelectionModal;
