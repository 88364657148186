import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { useBoundStore } from '../../store/useBoundStore';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Container,
  Text,
  VStack,
} from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import NotFoundPage from '../iframe/NotFoundComponent';
import { navigateToDefaultWorkspace } from '../../utils/getDefaultWorkspace';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'common-ts';

type ConfirmEmailError = {
  general?: string;
};

/**
 * Confirm email page. This page is used to confirm the email of a user.
 * The user is prompted to click the "Get Started" button to confirm their email.
 * This will verify the token and redirect the user to the default workspace.
 */
export default function ConfirmEmail() {
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<ConfirmEmailError>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const emailParam = searchParams.get('email');
    const tokenParam = searchParams.get('token');

    if (!emailParam || !tokenParam) {
      return;
    }

    setEmail(emailParam);
    setToken(tokenParam);
  }, [searchParams]);

  const handleConfirmation = async () => {
    setIsLoading(true);
    setError({});

    try {
      // Verify the token if it's not verified yet
      const { error: otpError } = await supabase.auth.verifyOtp({
        email,
        token,
        type: 'email',
      });

      if (otpError) {
        if (otpError.code === 'otp_expired') {
          setError({
            general: t('confirmEmailView.tokenExpired'),
          });
          setIsTokenExpired(true);
          return;
        }
        throw otpError;
      }

      navigateToDefaultWorkspace({
        navigateFn: navigate,
        showToast,
        supabase,
        translationFn: t,
      });
    } catch (err) {
      captureException(err);
      setError({
        general: t('confirmEmailView.confirmError'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendConfirmation = async () => {
    setIsLoading(true);
    setError({});

    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email,
      });

      if (error) throw error;

      setIsSuccess(true);
    } catch (err) {
      captureException(err);
      setError({
        general: t('confirmEmailView.resendError'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!email || !token) {
    return (
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }

  return (
    <Container maxW="md">
      <VStack spacing={6} align="stretch" className="py-8">
        <Text fontSize="2xl" fontWeight="bold" className="text-center">
          {t('confirmEmailView.welcome')}
        </Text>
        {error.general && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{error.general}</AlertDescription>
          </Alert>
        )}
        {isSuccess && (
          <Alert status="success">
            <AlertIcon />
            <AlertDescription>
              {t('confirmEmailView.resendSuccess')}
            </AlertDescription>
          </Alert>
        )}
        {!isTokenExpired ? (
          <Button
            className="bg-maia-accent text-maia-text-light w-full"
            onClick={handleConfirmation}
            isLoading={isLoading}
          >
            {t('confirmEmailView.getStarted')}
          </Button>
        ) : (
          <VStack spacing={4} className="w-full">
            <Button
              className="bg-maia-accent text-maia-text-light w-full"
              onClick={handleResendConfirmation}
              isLoading={isLoading}
            >
              {t('confirmEmailView.resendButton')}
            </Button>
            <Button
              className="bg-maia-accent text-maia-text-light w-full"
              onClick={() => navigate(ROUTES.AUTH.SIGN_IN.buildPath({}))}
              isLoading={isLoading}
            >
              {t('confirmEmailView.goToLogin')}
            </Button>
          </VStack>
        )}
      </VStack>
    </Container>
  );
}
