import {
  Button,
  Divider,
  FormLabel,
  IconButton,
  Input,
  Tooltip,
} from '@chakra-ui/react';
import {
  faCopy,
  faQuestionCircle,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import AccessControl from '../../components/AccessControl';
import ContentHeader from '../../components/ContentHeader';
import DeleteModal from '../../components/DeleteModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenerateInviteLinkWarningModal from './GenerateInviteLinkWarningModal';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import { fetchApi } from '../../utils/useApi';
import i18n from '../../i18n';
import { supportedLanguages } from 'common-ts';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

function GeneralSettings() {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { showToast } = useToastManagerHook();

  const [
    showGenerateInviteLinkWarningModal,
    setShowGenerateInviteLinkWarningModal,
  ] = useState(false);
  const [showDeleteInviteLinkModal, setShowDeleteInviteLinkModal] =
    useState(false);

  async function fetchWorkspaceInfos() {
    const res = await supabase
      .from('workspace')
      .select(
        'name, workspace_users_admin_view!workspace_user_workspace_id_fkey(user_type, first_name, last_name, email), workspace_admin_invite_code_view(invite_code)'
      )
      .eq('id', workspaceId);

    if (res.data?.[0]?.name) {
      setOldWorkspaceName(res.data[0].name);
    }
    const workspaceUsers = res.data?.[0]?.workspace_users_admin_view;
    const workspaceOwner = workspaceUsers?.find(
      (user) => user.user_type === 'OWNER'
    );
    if (workspaceOwner) {
      setWorkspaceOwnerName(
        workspaceOwner.first_name
          ? `${workspaceOwner.first_name} ${workspaceOwner.last_name ?? ''}`
          : workspaceOwner.email ?? ''
      );
      setWorkspaceOwnerMail(workspaceOwner.email ?? '');
    }
    const inviteCode =
      res.data?.[0]?.workspace_admin_invite_code_view?.invite_code;

    const inviteLink = inviteCode
      ? `${import.meta.env.VITE_MAIA_APP_PUBLIC_URL}/invite/${inviteCode}`
      : '';
    setInviteLink(inviteLink);
  }

  useEffect(() => {
    fetchWorkspaceInfos();
  }, []);

  async function handleWorkspaceNameChange(newName: string) {
    const res = await fetchApi(supabase, '/workspace', '/name', {
      method: 'PUT',
      name: newName,
      workspaceId,
    });

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    showToast({
      title: t('workspaceSettings.workspaceRenameSuccess'),
      status: 'success',
    });
    setOldWorkspaceName(newName);
  }

  async function handleGenerateInviteLink() {
    const res = await fetchApi(
      supabase,
      '/workspace',
      '/generate_invite_code',
      {
        method: 'POST',
        lang:
          supportedLanguages.find(
            (supportedLang) =>
              supportedLang.short === i18n.language.split('-')[0]
          )?.short ?? 'en',
        workspaceId,
      }
    );

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }
    const inviteCode = res.data.code;

    const inviteLink = inviteCode
      ? `${import.meta.env.VITE_MAIA_APP_PUBLIC_URL}/invite/${inviteCode}`
      : '';
    setInviteLink(inviteLink);
  }

  async function handleDisableInviteLink() {
    const res = await fetchApi(supabase, '/workspace', '/disable_invite_code', {
      method: 'POST',
      workspaceId,
    });

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    setInviteLink('');
  }

  const [workspaceOwnerName, setWorkspaceOwnerName] = useState('');
  const [workspaceOwnerMail, setWorkspaceOwnerMail] = useState('');
  const [oldWorkspaceName, setOldWorkspaceName] = useState('');
  const [newWorkspaceName, setNewWorkspaceName] = useState('');

  const [InviteLink, setInviteLink] = useState('');
  const [copyTooltipString, setCopyTooltipString] = useState(t('general.copy'));

  const handleCopy = () => {
    if (!InviteLink) return;

    navigator.clipboard.writeText(InviteLink);

    setCopyTooltipString(t('general.copied'));
    setTimeout(() => setCopyTooltipString(t('general.copy')), 1000);
  };

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('workspaceSettings.generalWorkspaceSettings')}
          icon={faUsers}
          buttons={[
            <Button
              className="font-semibold"
              isDisabled={
                !newWorkspaceName || newWorkspaceName === oldWorkspaceName
              }
              colorScheme="maia-accent"
              onClick={() => {
                handleWorkspaceNameChange(newWorkspaceName);
              }}
            >
              {t('general.saveButtons')}
            </Button>,
          ]}
          iconLabel={oldWorkspaceName}
        />
        <div>
          <FormLabel>{t('workspaceSettings.workspaceNameTitle')}</FormLabel>
          <Input
            type="text"
            autoFocus
            size="sm"
            maxLength={128}
            placeholder={oldWorkspaceName}
            value={newWorkspaceName}
            onChange={(e) => {
              setNewWorkspaceName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                newWorkspaceName &&
                newWorkspaceName !== oldWorkspaceName
              ) {
                handleWorkspaceNameChange(newWorkspaceName);
              }
            }}
          />
        </div>
        <div>
          <FormLabel>{t('workspaceSettings.workspaceOwnerTitle')}</FormLabel>
          <div>{`${workspaceOwnerName} (${workspaceOwnerMail})`}</div>
        </div>
        {/* ---------------------------------------------------------------- Invite link ---------------------------------------------------------------- */}
        <AccessControl allowedLicenses={['ENTERPRISE', 'PROFESSIONAL']}>
          <div>
            <FormLabel>
              {t('workspaceSettings.inviteLink')}{' '}
              <Tooltip label={t('workspaceSettings.inviteLinkExplanation')}>
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faQuestionCircle}
                />
              </Tooltip>
            </FormLabel>
            <div className="flex items-center gap-2">
              <Input
                isDisabled
                type="text"
                size="sm"
                value={InviteLink}
                placeholder={t('workspaceSettings.inviteLink')}
                className="cursor-text"
              />
              <Tooltip
                label={copyTooltipString}
                isDisabled={!InviteLink}
                closeDelay={500}
                fontSize={'xs'}
                placement="top"
              >
                <IconButton
                  variant={'outline'}
                  aria-label={t('general.copy')}
                  isDisabled={!InviteLink}
                  icon={
                    <FontAwesomeIcon icon={faCopy} className="fill-current" />
                  }
                  onClick={handleCopy}
                />
              </Tooltip>
              <Button
                className="flex-shrink-0"
                colorScheme="maia-accent"
                onClick={() => {
                  if (InviteLink) {
                    setShowGenerateInviteLinkWarningModal(true);
                  } else {
                    handleGenerateInviteLink();
                  }
                }}
              >
                {t('workspaceSettings.generateInviteLinkButton')}
              </Button>
              <Button
                className="flex-shrink-0"
                colorScheme="red"
                onClick={() => {
                  setShowDeleteInviteLinkModal(true);
                }}
              >
                {t('workspaceSettings.deactivateInviteLinkButton')}
              </Button>
            </div>
          </div>
        </AccessControl>
        <Divider />
        <div className="text-xs">
          {t('workspaceSettings.workspaceSupportInfo')}{' '}
          <a className="underline" href="mailto:info@prodlane.io">
            info@prodlane.io
          </a>
        </div>
      </MaiaFullGridWidthContent>
      <GenerateInviteLinkWarningModal
        isOpen={showGenerateInviteLinkWarningModal}
        onClose={() => {
          setShowGenerateInviteLinkWarningModal(false);
        }}
        onConfirm={async () => {
          await handleGenerateInviteLink();
          setShowGenerateInviteLinkWarningModal(false);
        }}
      />
      <DeleteModal
        isOpen={showDeleteInviteLinkModal}
        onClose={() => {
          setShowDeleteInviteLinkModal(false);
        }}
        onConfirm={() => {
          handleDisableInviteLink();
          setShowDeleteInviteLinkModal(false);
        }}
        title={t('workspaceSettings.deactivateLinkWarningTitle')}
        subtitle={t('workspaceSettings.deactivateLinkWarning')}
      />
    </MaiaMainGrid>
  );
}

export default GeneralSettings;
