import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
// @ts-ignore
import { ReactNode, useEffect, useMemo } from 'react';
import {
  faArrowUpFromBracket,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SupportedFileType } from 'common-ts';
import { useToastManagerHook } from '../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

type GenericUploadModalProps = {
  isOpen: boolean;
  children?: ReactNode;
  loading?: string;
  allowMultiUpload: boolean;
  /**
   * Only used for display. If you want to enforce this limit, you have to do it in `onFileSelect`.
   */
  fileSizeLimitMb?: number;
  /** There is no specific reason why this couldn't be a type that is not included in `SupportedFileType`
   * for example in the case that this Modal is used to upload something that is completely out of the scope of the embedding service.
   * This type was chosen for convenience as it provides a list of valid mimeTypes.
   * If you ever need to use this component for a mimeType not included here, feel free to create a new type for that.
   */
  acceptedMimeTypes: SupportedFileType[];
  onClose: () => void;
  onFileSelect: (files: FileList) => void;
};

function GenericUploadModal({
  isOpen,
  children,
  loading = undefined,
  allowMultiUpload,
  fileSizeLimitMb,
  acceptedMimeTypes,
  onClose,
  onFileSelect,
}: GenericUploadModalProps) {
  const { t } = useTranslation();
  const { showToast } = useToastManagerHook();

  const acceptedFileEndings = useMemo(() => {
    return Array.from(
      new Set(
        acceptedMimeTypes.map(
          (mimeType) => supportedFileTypeFileEndingMap[mimeType]
        )
      )
    );
  }, [acceptedMimeTypes]);

  useEffect(() => {
    const onDragOver = (e: DragEvent) => {
      e.preventDefault();
    };
    const onDrop = (e: DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (e.dataTransfer?.files) {
        for (const file of e.dataTransfer?.files) {
          if (!acceptedMimeTypes.includes(file.type as SupportedFileType)) {
            showToast({
              status: 'error',
              title: `${t('components.genericUploadModal.fileTypeWarning')}${acceptedFileEndings.join(', ')}`,
            });
            return;
          }
        }
        onFileSelect(e.dataTransfer.files);
      }
    };

    if (isOpen && !loading) {
      window.addEventListener('dragover', onDragOver);
      window.addEventListener('drop', onDrop);
    }

    return () => {
      window.removeEventListener('dragover', onDragOver);
      window.removeEventListener('drop', onDrop);
    };
  }, [isOpen, loading]);

  function handleFileUploadClick() {
    const input = document.createElement('input');
    input.multiple = allowMultiUpload;
    input.type = 'file';
    input.accept = acceptedFileEndings.join(', ');
    input.onchange = () => {
      if (input.files) {
        onFileSelect(input.files);
      }
    };

    input.click();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('components.genericUploadModal.header')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-2.5">
            <div className="border-maia-border bg-maia-gray-100 flex h-44 flex-col items-center justify-center gap-3 rounded-xl border">
              <FontAwesomeIcon
                icon={loading ? faSpinnerThird : faArrowUpFromBracket}
                className={`text-maia-gray-400 text-5xl ${loading ? 'animate-spin' : ''}`}
              />
              {loading ? (
                <div className="font-semibold">{loading}</div>
              ) : (
                <div className="font-semibold">
                  {t('components.genericUploadModal.dndInfo')}{' '}
                  <span
                    className="text-maia-accent cursor-pointer select-none"
                    onClick={handleFileUploadClick}
                  >
                    {t('components.genericUploadModal.select')}
                  </span>
                </div>
              )}
              <div className="text-chakra-gray-500 text-xs">
                {`${t('components.genericUploadModal.supportedTypesStart')} ${acceptedFileEndings.slice(0, acceptedFileEndings.length - 2).join(', ')}${acceptedFileEndings.length > 1 ? ` ${t('components.genericUploadModal.supportedTypesAnd')} ${acceptedFileEndings.slice(-2, -1)}` : ''}`}
              </div>
              {fileSizeLimitMb ? (
                <div className="text-chakra-gray-500 text-xs">
                  {t('components.genericUploadModal.fileSizeLimitMb', {
                    limitInMb: fileSizeLimitMb,
                  })}{' '}
                </div>
              ) : null}
            </div>
            {children}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex items-center justify-end gap-3">
            <Button variant="outline" onClick={onClose}>
              {t('general.cancelButton')}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const supportedFileTypeFileEndingMap: { [key in SupportedFileType]: string } = {
  'application/pdf': '.pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    '.pptx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '.docx',
  'text/csv': '.csv',
  'text/plain': '.txt',
  'text/plain;charset=UTF-8': '.txt',
};

export default GenericUploadModal;
